import React from 'react';
import { SectionHeader } from '../../../modules/common/components/section-header';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';

import '../../generic-page.scss';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import { Row } from 'react-bootstrap';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';

const AFKGuides: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page afk-journey '} game="afk">
      <ul className="breadcrumb">
        <li>
          <Link to="/afk-journey/">AFK Journey</Link>
        </li>
        <li className="divider">/</li>
        <li>Guides</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/afk/categories/category_guides.jpg"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>AFK Journey Guides</h1>
          <h2>
            Best guides for AFK Journey that will help you understand the game
            better
          </h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
      <SectionHeader title="New Season Content" />
      <Row
        xs={1}
        md={2}
        lg={2}
        xxl={3}
        className="g-3 category-cards-container"
      >
        <CategoryCard
          title="Artifacts - Season 3"
          link="/afk-journey/guides/artifacts-season"
          image={
            <StaticImage
              src="../../../images/afk/categories/category_arti_s.jpg"
              alt="Artifacts - Season 3"
            />
          }
        />
        <CategoryCard
          title="Charms - Season 3"
          link="/afk-journey/guides/charms-season"
          image={
            <StaticImage
              src="../../../images/afk/categories/category_charms.webp"
              alt="Charms - Season 3"
            />
          }
        />
        <CategoryCard
          title="Talents - Season 3"
          link="/afk-journey/guides/talents"
          image={
            <StaticImage
              src="../../../images/afk/categories/category_talents.jpg"
              alt="Talents"
            />
          }
        />
        <CategoryCard
          title="Dream Realm - Crazed Shellbrute"
          link="/afk-journey/guides/dream-realm-shellbrute"
          image={
            <StaticImage
              src="../../../images/afk/categories/category_brute.webp"
              alt="Dream Realm - Crazed Shellbrute"
            />
          }
        />
        <CategoryCard
          title="Dream Realm - Plague Creeper"
          link="/afk-journey/guides/dream-realm-creeper"
          image={
            <StaticImage
              src="../../../images/afk/categories/category_creep.webp"
              alt="Dream Realm - Plague Creeper"
            />
          }
        />
        <CategoryCard
          title="Dream Realm - Illucia"
          link="/afk-journey/guides/dream-realm-illucia"
          image={
            <StaticImage
              src="../../../images/afk/categories/category_illucia.webp"
              alt="Dream Realm - Illucia"
            />
          }
        />
        <CategoryCard
          title="Dream Realm - Thalassa"
          link="/afk-journey/guides/dream-realm-thalassa"
          image={
            <StaticImage
              src="../../../images/afk/categories/category_thal.webp"
              alt="Dream Realm - Thalassa"
            />
          }
        />
        <CategoryCard
          title="Primal Lord - Mirage Frostspike"
          link="/afk-journey/guides/primal-frostspike"
          image={
            <StaticImage
              src="../../../images/afk/categories/category_primalspike.webp"
              alt="Primal Lord - Mirage Frostspike"
            />
          }
        />
        <CategoryCard
          title="Primal Lord - Nocturne Judicator"
          link="/afk-journey/guides/primal-judicator"
          image={
            <StaticImage
              src="../../../images/afk/categories/category_primaljudi.webp"
              alt="Primal Lord - Nocturne Judicator"
            />
          }
        />
      </Row>
      <SectionHeader title="Newbie Zone" />
      <Row
        xs={1}
        md={2}
        lg={2}
        xxl={3}
        className="g-3 category-cards-container"
      >
        <CategoryCard
          title="Introduction to the game"
          link="/afk-journey/guides/introduction-to-the-game"
          image={
            <StaticImage
              src="../../../images/afk/categories/category_intro.jpg"
              alt="Introduction to the game"
            />
          }
        />
        <CategoryCard
          title="Frequently Asked Questions"
          link="/afk-journey/guides/frequently-asked-questions"
          image={
            <StaticImage
              src="../../../images/afk/categories/category_faq.jpg"
              alt="Frequently Asked Questions"
            />
          }
        />
        <CategoryCard
          title="Beginner guide"
          link="/afk-journey/guides/beginner-guide"
          image={
            <StaticImage
              src="../../../images/afk/categories/category_beginner.jpg"
              alt="Beginner guide"
            />
          }
        />
        <CategoryCard
          title="Tips and Tricks"
          link="/afk-journey/guides/tips-and-tricks"
          image={
            <StaticImage
              src="../../../images/afk/categories/category_tips.jpg"
              alt="Tips and Tricks"
            />
          }
        />
        <CategoryCard
          title="Reroll guide"
          link="/afk-journey/guides/reroll"
          image={
            <StaticImage
              src="../../../images/afk/categories/category_reroll.jpg"
              alt="Reroll guide"
            />
          }
          updated
        />
        <CategoryCard
          title="Wishlist tips"
          link="/afk-journey/guides/wishlist-tips"
          image={
            <StaticImage
              src="../../../images/afk/categories/category_wishlist.jpg"
              alt="Wishlist tips"
            />
          }
          updated
        />
        <CategoryCard
          title="Best AFK Stages Teams"
          link="/afk-journey/guides/afk-progression-teams"
          image={
            <StaticImage
              src="../../../images/afk/categories/category_team.jpg"
              alt="Best AFK Stages Teams"
            />
          }
        />
        <CategoryCard
          title="Best Arena Teams"
          link="/afk-journey/guides/arena-teams"
          image={
            <StaticImage
              src="../../../images/afk/categories/category_meta.jpg"
              alt="Best Arena Teams"
            />
          }
        />
        <CategoryCard
          title="Best Supreme Arena Teams"
          link="/afk-journey/guides/supreme-arena-teams"
          image={
            <StaticImage
              src="../../../images/afk/categories/category_sarena.webp"
              alt="Best Supreme Arena Teams"
            />
          }
        />
        <CategoryCard
          title="Shop (Emporium) guide"
          link="/afk-journey/guides/shop-guide"
          image={
            <StaticImage
              src="../../../images/afk/categories/category_shops.jpg"
              alt="Shop (Emporium) guide"
            />
          }
        />
        <CategoryCard
          title="Exclusive Weapon priority"
          link="/afk-journey/guides/exclusive-weapon-priority"
          image={
            <StaticImage
              src="../../../images/afk/categories/category_ex.jpg"
              alt="Exclusive Weapon priority"
            />
          }
          updated
        />
        <CategoryCard
          title="Promo codes"
          link="/afk-journey/codes"
          image={
            <StaticImage
              src="../../../images/afk/categories/category_codes.jpg"
              alt="Promo codes"
            />
          }
        />
      </Row>
      <SectionHeader title="Generic guides" />
      <Row
        xs={1}
        md={2}
        lg={2}
        xxl={3}
        className="g-3 category-cards-container"
      >
        <CategoryCard
          title="Artifacts"
          link="/afk-journey/guides/artifacts"
          image={
            <StaticImage
              src="../../../images/afk/categories/category_artifacts.jpg"
              alt="Artifacts"
            />
          }
        />
        <CategoryCard
          title="Ascension info"
          link="/afk-journey/guides/ascension-info"
          image={
            <StaticImage
              src="../../../images/afk/categories/category_ascension.jpg"
              alt="Ascension info"
            />
          }
        />
        <CategoryCard
          title="Banners and rates"
          link="/afk-journey/guides/banners-and-rates"
          image={
            <StaticImage
              src="../../../images/afk/categories/category_gacha.jpg"
              alt="Banners and rates"
            />
          }
        />
        <CategoryCard
          title="Characters info"
          link="/afk-journey/guides/characters-info"
          image={
            <StaticImage
              src="../../../images/afk/categories/category_char.jpg"
              alt="Characters info"
            />
          }
        />
        <CategoryCard
          title="Characters list"
          link="/afk-journey/characters"
          image={
            <StaticImage
              src="../../../images/afk/categories/category_characters.jpg"
              alt="Characters list"
            />
          }
        />
        <CategoryCard
          title="Equipment & upgrade priority"
          link="/afk-journey/guides/equipment-and-priority"
          image={
            <StaticImage
              src="../../../images/afk/categories/category_eq.jpg"
              alt="Equipment & upgrade priority"
            />
          }
        />
        <CategoryCard
          title="Game modes"
          link="/afk-journey/guides/game-modes"
          image={
            <StaticImage
              src="../../../images/afk/categories/category_modes.jpg"
              alt="Game modes"
            />
          }
        />
        <CategoryCard
          title="Guilds"
          link="/afk-journey/guides/guilds"
          image={
            <StaticImage
              src="../../../images/afk/categories/category_guilds.jpg"
              alt="Guilds"
            />
          }
        />
        <CategoryCard
          title="Guilds - Battle Drills"
          link="/afk-journey/guides/guilds-battle-drill"
          image={
            <StaticImage
              src="../../../images/afk/categories/category_battle.jpg"
              alt="Guilds - Battle Drills"
            />
          }
        />
        <CategoryCard
          title="Guilds - Glyphshade boss"
          link="/afk-journey/guides/guild-glyphshade"
          image={
            <StaticImage
              src="../../../images/afk/categories/category_glyph.webp"
              alt="Guild - Glyphshade boss"
            />
          }
          updated
        />
        <CategoryCard
          title="Spending guide"
          link="/afk-journey/guides/spending-guide"
          image={
            <StaticImage
              src="../../../images/afk/categories/category_spending.jpg"
              alt="Spending guide"
            />
          }
        />
      </Row>
      <SectionHeader title="Dream Realm" />
      <Row
        xs={1}
        md={2}
        lg={2}
        xxl={3}
        className="g-3 category-cards-container"
      >
        <CategoryCard
          title="Dream Realm - Overview"
          link="/afk-journey/guides/dream-realm"
          image={
            <StaticImage
              src="../../../images/afk/categories/category_dream.jpg"
              alt="Dream Realm - Overview"
            />
          }
        />
        <CategoryCard
          title="Crystal Beetle"
          link="/afk-journey/guides/dream-realm-crystal-beetle"
          image={
            <StaticImage
              src="../../../images/afk/categories/category_crystal.webp"
              alt="Crystal Beetle"
            />
          }
          updated
        />
        <CategoryCard
          title="Orson"
          link="/afk-journey/guides/dream-realm-orson"
          image={
            <StaticImage
              src="../../../images/afk/categories/category_orson.webp"
              alt="Orson"
            />
          }
          updated
        />
        <CategoryCard
          title="Lone Gaze"
          link="/afk-journey/guides/dream-realm-lone-gaze"
          image={
            <StaticImage
              src="../../../images/afk/categories/category_lone.jpg"
              alt="Primal Lord - Lone Gaze"
            />
          }
          updated
        />
        <CategoryCard
          title="Snow Stomper"
          link="/afk-journey/guides/dream-realm-snow-stomper"
          image={
            <StaticImage
              src="../../../images/afk/categories/category_yeti.jpg"
              alt="Snow Stomper"
            />
          }
          updated
        />
        <CategoryCard
          title="Dream Realm - Crazed Shellbrute"
          link="/afk-journey/guides/dream-realm-shellbrute"
          image={
            <StaticImage
              src="../../../images/afk/categories/category_brute.webp"
              alt="Dream Realm - Crazed Shellbrute"
            />
          }
        />
        <CategoryCard
          title="Dream Realm - Plague Creeper"
          link="/afk-journey/guides/dream-realm-creeper"
          image={
            <StaticImage
              src="../../../images/afk/categories/category_creep.webp"
              alt="Dream Realm - Plague Creeper"
            />
          }
        />
        <CategoryCard
          title="Dream Realm - Illucia"
          link="/afk-journey/guides/dream-realm-illucia"
          image={
            <StaticImage
              src="../../../images/afk/categories/category_illucia.webp"
              alt="Dream Realm - Illucia"
            />
          }
        />
        <CategoryCard
          title="Dream Realm - Thalassa"
          link="/afk-journey/guides/dream-realm-thalassa"
          image={
            <StaticImage
              src="../../../images/afk/categories/category_thal.webp"
              alt="Dream Realm - Thalassa"
            />
          }
        />
      </Row>
    </DashboardLayout>
  );
};

export default AFKGuides;

export const Head: React.FC = () => (
  <Seo
    title="Guides | AFK Journey | Prydwen Institute"
    description="Best guides for AFK Journey that will help you understand the game better."
    game="afk"
  />
);
